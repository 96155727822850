<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="9" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="org_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('org_pro.choose_organization') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="bank.org_id"
                      :options="orgList"
                      id="org_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Component" vid="component_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="component_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('org_pro.choose_component') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="bank.component_id"
                      :options="componentList"
                      id="component_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Bank Name (En)" vid="bank_name" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="bank_name"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                     {{ $t('org_pro.bank_name') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="bank_name"
                      v-model="bank.bank_name"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Bank Name (Bn)" vid="bank_name_bn" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="bank_name_bn"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                     {{ $t('org_pro.bank_name_bn') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="bank_name_bn"
                      v-model="bank.bank_name_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { commonServiceBaseUrl } from '../../../../../config/api_config'
import { bankStore, bankUpdate } from '../../api/routes'

export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getBankData()
    //   Object.freeze(tmp)
      this.bank = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      bank: {
        org_id: '0',
        component_id: '0',
        bank_name: '',
        bank_name_bn: '',
        editable: false
      }
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    componentList: function () {
      const listObject = this.$store.state.commonObjCommonConfig.componentList.filter(item => item.status === 0)
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
      return tmpList
    }
  },
  methods: {
    default () {
      return {
        id: this.rows.length,
        org_id: '',
        component_id: '',
        bank_name: '',
        bank_name_bn: '',
        editable: false
      }
    },
    getBankData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.bank.id) {
        result = await RestApi.putData(commonServiceBaseUrl, `${bankUpdate}/${this.id}`, this.bank)
      } else {
        result = await RestApi.postData(commonServiceBaseUrl, bankStore, this.bank)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
            this.$store.commit('mutateDropdownCommonConfig', { hasDropdownLoaded: false })
            this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            color: '#D6E09B'
            })

            this.$bvModal.hide('modal-4')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
