<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('org_pro.bank') }}</h4>
      </template>
      <template v-slot:body>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro.organization')"
                    label-for="org_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.org_id"
                        :options="orgList"
                        id="org_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro.component')"
                    label-for="component_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.component_id"
                        :options="componentList"
                        id="component_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro.bank_namel')"
                    label-for="bank_name"
                    >
                    <b-form-input
                        id="bank_name"
                        v-model="search.bank_name"
                        placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
            </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('org_pro.bank_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(org_name)="data">
                      <span class="capitalize">{{ data.item.org_name }}</span>
                    </template>
                    <template v-slot:cell(org_name_bn)="data">
                      {{ data.item.org_name_bn }}
                    </template>
                    <template v-slot:cell(component_name)="data">
                      <span class="capitalize">{{ data.item.component_name }}</span>
                    </template>
                    <template v-slot:cell(component_name_bn)="data">
                      {{ data.item.component_name_bn }}
                    </template>
                    <template v-slot:cell(bank_name)="data">
                      <span class="capitalize">{{ data.item.bank_name }}</span>
                    </template>
                    <template v-slot:cell(bank_name_bn)="data">
                      {{ data.item.bank_name_bn }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge" :class="data.item.status ? 'badge-danger' : 'badge-success'">{{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_status" title="Change Status" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Change Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import FormV from './Form'
import { mapGetters } from 'vuex'
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import { bankList, bankToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
        org_id: 0,
        component_id: 0,
        bank_name: ''
      },
      rows: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    componentList: function () {
      return this.authorizedComponents.map(item => {
        return { value: item.id, text: this.$i18n.locale === 'en' ? item.component_name : item.component_name_bn }
      })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('org_pro.bank_entry') : this.$t('org_pro.bank_modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('org_pro.organization'), class: 'text-center' },
          { label: this.$t('org_pro.component'), class: 'text-center' },
          { label: this.$t('org_pro.bank'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'component_name_bn' },
          { key: 'bank_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'component_name' },
          { key: 'bank_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    ...mapGetters({
      authUser: 'Auth/authUser',
      authorizedComponents: 'Auth/getAuthorizedComponents'
    })
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(commonServiceBaseUrl, bankToggleStatus, item, 'common_config', 'bankList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(commonServiceBaseUrl, bankList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
